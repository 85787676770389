import calculateTemplatePricing from "./calculateTemplatePricing";

export default function calculatePricingAdjustments({
  pricingAdjustments = [],
  duration = 1,
  templateId,
  adData,
  adTextHeights,
  templatePricingTotal,
}) {
  return pricingAdjustments.reduce((temp, adjustment) => {
    if (!adjustment) return temp;

    if (adjustment?.pricingType === "PERCENTAGE") {
      const percentage = adjustment.value / 100;
      const total = templatePricingTotal * duration;
      return temp + total * percentage;
    }

    if (adjustment?.name.toLowerCase() === "facebook") {
      return temp;
    }

    if (adjustment.frequency === "PER_DAY") {
      return temp + adjustment.value * duration;
    }

    if (!adjustment.linkedPricing) {
      return temp + adjustment.value;
    }

    if (adjustment.linkedPricing) {
      return (
        temp +
        calculateTemplatePricing({
          templatePricing: adjustment.linkedPricing?.[templateId] || {},
          items: adData,
          itemHeights: adTextHeights,
        })
      );
    }

    return temp;
  }, 0);
}
