import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import requests from "../graphql/serverRequest";

import useDataPortal from "./useDataPortal";

export default function usePortalNavItems() {
  const portal = useDataPortal();

  const [items, setItems] = useState([]);

  const [getPortalNavItems] = useLazyQuery(requests.NavItemsQuery, {
    onCompleted: (data) => {
      setItems(data?.NavItems || []);
    },
  });

  useEffect(() => {
    if (!portal?.id) return;

    getPortalNavItems({
      variables: {
        portalId: portal.id,
      },
    });
  }, [portal]);

  return items;
}
