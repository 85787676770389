import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";

import Loader from "./Loader";
import styles from "../../../scss/components/general/Overlay.module.scss";

const Overlay = ({ t, label }) => (
  <div className={styles.overlay}>
    <Loader />
    <h3>{label || t("label")}</h3>
  </div>
);

Overlay.defaultProps = {
  label: null,
};

Overlay.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default withTranslation("overlay")(Overlay);
