/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { createContext, useState, useCallback } from "react";

import useHydratePricingContextFromLocalStorage from "./useHydratePricingContextFromLocalStorage";
import useSyncLocalStorage from "./useSyncLocalStorage";
import useResetPricing from "./useResetPricing";
import useFormattedTotal from "./useFormattedTotal";
import useUpdatePricingTotal from "./useUpdatePricingTotal";

export const PricingContext = createContext(undefined);

export const PROMO_COUPON_KEY = "_promoCoupon";

export const PricingProvider = (props) => {
  const [total, _setTotal] = useState(null);
  const [promoCoupon, setPromoCoupon] = useState(null);

  const setTotal = useCallback(
    (newTotal) => {
      _setTotal(newTotal);
    },
    [_setTotal]
  );

  // Hydrate pricing context from local storage on mount
  useHydratePricingContextFromLocalStorage({ setPromoCoupon });

  // Sync local storage with pricing context
  useSyncLocalStorage({ total, promoCoupon });

  // Format total for display
  const { formattedTotal, setFormattedTotal } = useFormattedTotal({ total });

  // Reset pricing context
  const resetPricing = useResetPricing({
    setTotal,
    setFormattedTotal,
    setPromoCoupon,
  });

  // Update pricing total
  useUpdatePricingTotal({
    setTotal,
    promoCoupon,
  });

  const providerValue = {
    total,
    formattedTotal,
    resetPricing,
    promoCoupon,
    setPromoCoupon,
  };

  return <PricingContext.Provider value={providerValue}>{props.children}</PricingContext.Provider>;
};
