import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import HeaderLinkBox from "./HeaderLinkBox";
import styles from "../../../scss/components/header/HeaderMenu.module.scss";

const HeaderMenu = ({ authStatus, listMenu }) => (
  <ul className={styles.container}>
    {listMenu.map((item, index) => (
      <HeaderLinkBox
        key={[index, item.url].join("_")}
        asPath={item.url}
        href="/[infoPage]"
        name={item.label}
        typeStyle="headerMenu"
      />
    ))}
    <HeaderLinkBox
      href={!authStatus ? "/login" : "/account"}
      asPath={!authStatus ? "/login" : "/account"}
      name={!authStatus ? "Log In" : "Account"}
      typeStyle="headerMenu"
      typeMenu="mobMenu"
    />
  </ul>
);

HeaderMenu.defaultProps = {
  authStatus: false,
  listMenu: [],
};

HeaderMenu.propTypes = {
  t: PropTypes.func.isRequired,
  authStatus: PropTypes.bool,
  listMenu: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withTranslation("indexPage")(HeaderMenu);
