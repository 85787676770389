/* eslint-disable react/no-unknown-property */
import PropTypes from "prop-types";
import PrismLink from "./PrismLink";

const Logo = ({ href, text, urlLogo, styleType }) => (
  <>
    <PrismLink href={href} typeStyle={styleType}>
      <div>
        <img src={urlLogo} alt="logo" />
        {text && <span>{text}</span>}
      </div>
    </PrismLink>
    <style jsx>{`
      div {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    `}</style>
  </>
);

Logo.defaultProps = {
  text: "",
  styleType: "",
  urlLogo: "",
  href: "",
};

Logo.propTypes = {
  text: PropTypes.string,
  styleType: PropTypes.string,
  urlLogo: PropTypes.string,
  href: PropTypes.string,
};

export default Logo;
