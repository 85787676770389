import PropTypes from "prop-types";
import styles from "../../../scss/components/general/TextList.module.scss";
import ListItems from "./ListItems";
import ListText from "./ListText";

const TextList = ({ title, selectedItem }) =>
  selectedItem?.item?.[0]?.length > 0 || selectedItem?.items?.[0]?.list?.length > 0 ? (
    <div className={styles.textInfo}>
      <span>{`${title} ${selectedItem.title}:`}</span>
      <ListText selectedItem={selectedItem} />
      <ListItems selectedItem={selectedItem} />
    </div>
  ) : null;

TextList.defaultProps = {
  title: "",
  selectedItem: {},
};

TextList.propTypes = {
  title: PropTypes.string,
  selectedItem: PropTypes.shape({
    title: PropTypes.string,
    item: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
};

export default TextList;
