import { useRef, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";

import request from "../graphql/serverRequest";

const useFetchPackage = () => {
  const promise = useRef();

  const [fetch] = useLazyQuery(request.Package, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted(data) {
      promise.current.resolve(data?.Package);
    },
    onError: (error) => {
      promise.current.reject(error);
    },
  });

  return useCallback(({ portalId, packageId }) => {
    promise.current = {};
    promise.current.promise = new Promise((resolve, reject) => {
      promise.current.resolve = resolve;
      promise.current.reject = reject;
    });

    fetch({
      variables: {
        input: {
          portalId,
          id: packageId,
        },
      },
    });

    return promise.current.promise;
  }, []);
};

export default useFetchPackage;
