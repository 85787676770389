import { useSyncOrderUpdatesWithLocalStorage } from "hooks/useSyncOrderUpdatesWithLocalStorage";
import { createContext, useState } from "react";
import { useMutation } from "@apollo/client";
import request from "src/graphql/serverRequest";
import { cloneDeep } from "lodash";

import logger from "utils/loggerFrontEnd";

export const OrderUpdatesContext = createContext({});

export const OrderUpdatesProvider = ({ children }) => {
  const [orderUpdates, _setOrderUpdates] = useState();

  const { set } = useSyncOrderUpdatesWithLocalStorage();

  const [createOrderUpdate] = useMutation(request.CreateOrderUpdate);

  function setOrderUpdates(newOrderData) {
    set(newOrderData);
    _setOrderUpdates(newOrderData);
  }

  async function saveOrderUpdates(orderId) {
    await logger.debug(`Saving order updates`, {
      orderId,
      previousPricing: orderUpdates?.previousPricing,
      newPricing: orderUpdates?.newPricing,
    });

    // somewhere in the codebase we are setting the schedule dates
    // in the format "Fri, 28 Jul 2023 07:00:00 GMT"

    const newPricing = convertDatesInObject(orderUpdates?.newPricing);
    const previousPricing = convertDatesInObject(orderUpdates?.previousPricing);

    try {
      await createOrderUpdate({
        variables: {
          input: {
            orderId: orderId,
            previousPricing,
            newPricing,
          },
        },
      });
    } catch (error) {
      await logger.error(`Error saving order updates: ${error.message}`, {
        orderId,
        orderUpdates,
        error: error.message,
      });
    }
  }

  return (
    <OrderUpdatesContext.Provider
      value={{
        orderUpdates,
        setOrderUpdates,
        saveOrderUpdates,
      }}
    >
      {children}
    </OrderUpdatesContext.Provider>
  );
};

function convertDate(dateString) {
  try {
    // Create a new date object from the input string
    let date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
      throw new Error("Invalid date format");
    }

    // Convert the date object to an ISO string and return it
    return date.toISOString();
  } catch (error) {
    console.error(error);
    return null;
  }
}

function convertDatesInObject(source) {
  if (!source) return source;

  // Clone the source object
  let obj = cloneDeep(source);

  // Iterate over the keys in the object
  for (let key in obj) {
    // Check if the key has a "datesAffected" property
    if (obj[key].hasOwnProperty("datesAffected")) {
      // Convert each date in the "datesAffected" array
      obj[key].datesAffected = obj[key].datesAffected.map(convertDate);
    }

    // Check if the key has a "schedule" property
    if (obj[key].hasOwnProperty("schedule")) {
      // Convert each date in the "schedule" array
      obj[key].schedule = obj[key].schedule.map(convertDate);
    }
  }

  return obj;
}
