import PropTypes from "prop-types";
import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import styles from "scss/components/general/PrismLink.module.scss";

const PrismLink = ({ children, typeStyle, href, asPath, dashboard }) => {
  const router = useRouter();
  const active = router.asPath === asPath ? "activeLink" : "";
  const activeDashboard = dashboard === true && router.asPath === asPath ? "activeLinkDashboard" : "";
  return (
    <Link href={href} as={asPath} passHref>
      <a className={`${styles[typeStyle]} ${active && styles[active]} ${activeDashboard && styles[activeDashboard]}`}>
        {children}
      </a>
    </Link>
  );
};

PrismLink.defaultProps = {
  typeStyle: "",
  href: "",
  asPath: "",
  dashboard: false,
};

PrismLink.propTypes = {
  children: PropTypes.node.isRequired,
  typeStyle: PropTypes.string,
  href: PropTypes.string,
  asPath: PropTypes.string,
  dashboard: PropTypes.bool,
};

export default PrismLink;
