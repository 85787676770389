/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from "react";
import { PublicationProvider } from "./publicationContext";
import { PricingProvider } from "./pricingContext";
import { OrderDataProvider } from "./orderContext";
import { AppProvider } from "./appContext";
import { AdBuilderProvider } from "./adBuilderContext";
import { SaveDraftProvider } from "./saveDraftContext";
import { FuneralHomeProvider } from "./funeralHomeContext";
import { OrderUpdatesProvider } from "./orderUpdates";
import { RoutingContextProvider } from "./routingContext";

const GlobalContextProvider = (props) => {
  return (
    <AppProvider>
      <PublicationProvider>
        <OrderDataProvider>
          <AdBuilderProvider>
            <OrderUpdatesProvider>
              <PricingProvider>
                <RoutingContextProvider portalId={props?.portalId}>
                  <SaveDraftProvider>
                    <FuneralHomeProvider>{props.children}</FuneralHomeProvider>
                  </SaveDraftProvider>
                </RoutingContextProvider>
              </PricingProvider>
            </OrderUpdatesProvider>
          </AdBuilderProvider>
        </OrderDataProvider>
      </PublicationProvider>
    </AppProvider>
  );
};

export default GlobalContextProvider;
