import PropTypes from "prop-types";
import ClientOnlyPortal from "../components/general/ClientOnlyPortal";

import styles from "../../scss/layouts/ModalLayout.module.scss";

const ModalLayout = ({ children, typeModal, typeContainer }) => (
  <ClientOnlyPortal selector="#modal">
    <div className={`${styles.container} ${typeContainer && styles[typeContainer]}`}>
      <div className={typeModal && styles[typeModal]}>{children}</div>
    </div>
  </ClientOnlyPortal>
);

export default ModalLayout;
ModalLayout.defaultProps = {
  typeModal: "",
  typeContainer: "",
};

ModalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  typeModal: PropTypes.string,
  typeContainer: PropTypes.string,
};
