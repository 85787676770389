import gql from "graphql-tag";

const request = {
  EmailOrderAcknowledgement: gql`
    query ($orderId: String, $to: String, $useShippingInfo: Boolean) {
      OrderAcknowledgementEmail(orderId: $orderId, to: $to, useShippingInfo: $useShippingInfo)
    }
  `,
  OrderMostRecentChange: gql`
    query ($input: OrderChangeInput!) {
      OrderMostRecentChange(input: $input) {
        id
      }
    }
  `,
  CreateOrderUpdate: gql`
    mutation ($input: OrderUpdateInput!) {
      CreateOrderUpdate(input: $input) {
        orderId
        previousPricing
        newPricing
        dateUpdated
      }
    }
  `,
  GenerateAdImage: gql`
    mutation ($input: AdImageInput!) {
      GenerateAdImage(input: $input) {
        pdf
        jpg
        height
        width
        textHeights
      }
    }
  `,
  GenerateSocialAdPreview: gql`
    mutation ($input: FacebookAdPreviewInput) {
      FacebookAdPreview(input: $input)
    }
  `,
  GenerateSocialImage: gql`
    mutation ($input: FacebookCoverImageInput) {
      FacebookCoverImage(input: $input)
    }
  `,
  Upload: gql`
    mutation Upload($input: UploadInput!) {
      Upload(input: $input) {
        src
      }
    }
  `,
  Uploads: gql`
    mutation Uploads($input: [UploadInput]) {
      Uploads(input: $input) {
        src
      }
    }
  `,
  LOGIN_USER: gql`
    mutation login($input: LoginInput!) {
      login(input: $input) {
        auth {
          token
          refreshToken
        }
        profile {
          firstName
          lastName
          userType
          portals
          email
          phone
          address
          zipCode
          state
          city
          country
          billAccount
        }
      }
    }
  `,
  REFRESH_TOKEN: gql`
    mutation refreshToken($input: RefreshInput!) {
      refresh(input: $input) {
        success {
          success
          error {
            message
            error
          }
        }
        auth {
          token
          refreshToken
        }
      }
    }
  `,
  UPDATE_MY_PROFILE: gql`
    mutation updateMyProfile($input: UpdateUser!) {
      updateMyProfile(input: $input) {
        firstName
        lastName
        userType
        email
        phone
        address
        zipCode
        state
        city
        country
      }
    }
  `,
  Publications: gql`
    query Publications($input: PublicationQueryInput!) {
      Publications(input: $input) {
        key
        publications {
          id
          name
          portalId
          description
          minimumPrice
          logo {
            src
            alt
          }
          packages {
            id
            pricing {
              addOns
              basePrice
              perSquareInch
              flatRate
              pricingAdjustments {
                pricingId
                value
                frequency
                name
                linkedPublication
                linkedPricing
                pricingType
              }
              socialMediaPricing
              templatePricing
              multiplePublicationTemplatePricing
            }
            name
            description
          }
          specialSchedule {
            MONDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            TUESDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            WEDNESDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            THURSDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            FRIDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            SATURDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            SUNDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
          }
          standardSchedule {
            MONDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            TUESDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            WEDNESDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            THURSDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            FRIDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            SATURDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
            SUNDAY {
              cutoffTime {
                hour
                minute
                dayTime
              }
              publishingOffsetInHours
            }
          }
          email
          phoneNumber
          address
          newspaperGrayscale
        }
      }
    }
  `,

  PublicationInfo: gql`
    query PublicationInfo($input: getPublicationInput!) {
      GetPublication(input: $input) {
        id
        name
        portalId
        description
        minimumPrice
        logo {
          src
          alt
        }
        email
        phoneNumber
        address
        newspaperGrayscale
      }
    }
  `,

  GetPublicationMaxAdHeight: gql`
    query GetPublicationMaxAdHeight($input: getPublicationInput!) {
      GetPublication(input: $input) {
        id
        portalId
        newspaperMaxHeight
      }
    }
  `,

  Publication: gql`
    query GetPublication($input: getPublicationInput!) {
      GetPublication(input: $input) {
        id
        name
        portalId
        description
        minimumPrice
        logo {
          src
          alt
        }
        packages {
          id
          pricing {
            addOns
            basePrice
            perSquareInch
            flatRate
            pricingAdjustments {
              pricingId
              value
              frequency
              name
              linkedPublication
              linkedPricing
              pricingType
              adVersion
              adType
            }
            socialMediaPricing
            templatePricing
            multiplePublicationTemplatePricing
          }
          name
          description
        }
        email
        phoneNumber
        address
        newspaperGrayscale
      }
    }
  `,

  AdditionalPublications: gql`
    query ($input: PublicationQueryInput!, $packageId: String!, $activePublications: [String]) {
      AdditionalPublications(input: $input, packageId: $packageId, activePublications: $activePublications) {
        key
        publications {
          id
          name
          portalId
          description
          minimumPrice
          logo {
            src
            alt
          }
          packages {
            id
            pricing {
              basePrice
              perSquareInch
              flatRate
              pricingAdjustments {
                pricingId
                value
                frequency
                name
                linkedPublication
                linkedPricing
                pricingType
              }
              socialMediaPricing
              templatePricing
              multiplePublicationTemplatePricing
            }
            name
            description
          }
          email
          phoneNumber
          address
          newspaperGrayscale
        }
      }
    }
  `,

  Dynamic: gql`
    query getDynamicPage($pagename: String) {
      getDynamicPage(pagename: $pagename) {
        dynamicPage {
          pageName
          content {
            week {
              tuesday
              wednesday
              thursday
              friday
              saturday
              saturdayAlt
              monday
            }
            text
            textInformation
            name
          }
        }
      }
    }
  `,
  getInputs: gql`
    query getFormBuildPublication($input: InputTemp!) {
      getFormBuildPublication(input: $input) {
        ListInput {
          idTemplate
          width
          height
          elements {
            id
            maxlength
            count
            required
            type
            y
            x
            url
            width
            height
            fontWeight
            fontFamily
            fontSize
            textAlign
            text
            alignment
            textDecoration
            margin {
              value
              type
            }
          }
        }
      }
    }
  `,
  OrderByFriendlyId: gql`
    query ($input: OrdersSearchInput!) {
      OrdersByFriendlyId(input: $input) {
        items {
          id
          status
          friendlyId
          createdAt
          img: printTemplatePreviewImage
          adImagePreview
        }
      }
    }
  `,
  getOrderCards: gql`
    query Orders($input: OrdersInput!) {
      Orders(input: $input) {
        key
        items {
          id
          status
          friendlyId
          createdAt
          printTemplatePreviewImage
          adImagePreview
        }
      }
    }
  `,
  GetStateList: gql`
    query getState($idPortal: String) {
      getState(idPortal: $idPortal) {
        stateList
      }
    }
  `,
  CityList: gql`
    query getCity($input: InputState) {
      getCity(input: $input) {
        cities {
          state
          city
        }
      }
    }
  `,
  registerUser: gql`
    mutation registerUser($input: RegisterUser!, $portalId: String) {
      registerUser(input: $input, portalId: $portalId) {
        auth {
          token
          refreshToken
        }
        profile {
          firstName
          lastName
          portals
          userType
          email
          phone
          address
          zipCode
          state
          city
          country
        }
      }
    }
  `,
  getMyProfile: gql`
    query getMyProfile {
      getMyProfile {
        id
        specialId
        firstName
        lastName
        userType
        portals
        email
        phone
        address
        zipCode
        state
        city
        country
        funeralHomeId
        billAccount
        specialUserType
      }
    }
  `,
  Package: gql`
    query Package($input: PackageInput!) {
      Package(input: $input) {
        id
        portalId
        name
        facebookAdSetting
        description
        restrictToSpecialUsers
        userTypes {
          userTypeId
        }
        privatePackage
        permissions
        templateGroups {
          templateGroupId
          facebookTemplate {
            templates
            adDurationInDays
            requireSequentialScheduling
          }
          digitalTemplate {
            templates
            adDurationInDays
            requireSequentialScheduling
          }
          printAd {
            templates
            requireSequentialScheduling
          }
        }
        isActive
        type
        price
      }
    }
  `,
  SocialTemplates: gql`
    query SocialTemplate($input: SocialTemplatesInput) {
      SocialTemplate(input: $input) {
        id
        portalId
        name
        title
        width
        height
        maxHeight
        elements
        date
        image
        grayscaleImage
        isActive
        type
        imageWrapper
        socialKey
      }
    }
  `,
  Templates: gql`
    query Templates($input: [TemplatesInput]) {
      Templates(input: $input) {
        templates {
          id
          portalId
          description
          name
          title
          width
          height
          maxHeight
          elements
          date
          image
          grayscaleImage
          isActive
          type
          imageWrapper
        }
      }
    }
  `,
  GetUpgradeSocial: gql`
    query getUserInfo($id: String) {
      getUserInfo(id: $id) {
        userInfo {
          id
          image
          listTargetDemographic {
            id
            value
            label
          }
          chooseDate {
            from {
              date
              year
            }
            to {
              date
              year
            }
          }
          facebook {
            name
            price
          }
        }
        error
      }
    }
  `,
  GetOrderPrice: gql`
    query GetOrder($input: PackageInput!) {
      GetOrder(input: $input) {
        price {
          pricingAdjustmentCosts {
            value
            pricingId
            frequency
            name
          }
          totalCost
        }
        sendingDate
        templates {
          printTemplate {
            imgUrl
          }
        }
      }
    }
  `,
  UpdateOrder: gql`
    mutation UpdateOrder($input: OrderInput!) {
      UpdateOrder(input: $input) {
        id
        status
        userId
        packageId
        portalId
        templates {
          digitalTemplateId
          facebookTemplateId
          printTemplateId
        }
        price {
          totalCost
        }
        scheduling {
          digitalAdd {
            templateGroupId
            schedulingDates {
              publicationId
              date
            }
          }
          facebookAdd {
            templateGroupId
            schedulingDates {
              publicationId
              date
            }
          }
          printAdd {
            templateGroupId
            schedulingDates {
              publicationId
              date
            }
          }
        }
        adData
        publicationOrderData
      }
    }
  `,
  SubmitOrder: gql`
    mutation SubmitOrder($input: SubmitOrderInput!) {
      SubmitOrder(input: $input)
    }
  `,
  GetUnavailableDays: gql`
    query getUnavailableDays($input: InputDay) {
      getUnavaibleDays(input: $input) {
        unavailableDays {
          idNewsPapper
          unavailableDays
        }
      }
    }
  `,
  Portal: gql`
    query Portal($id: String!) {
      Portal(id: $id) {
        id
        name
        homePageBannerText
        homePageWelcomeText
        emblems {
          id
          name
          url
        }
        specialTemplateFields {
          id
          name
        }
        homepageDesign
      }
    }
  `,
  GetPackageTemplateGroupsWithPrice: gql`
    query Package($input: PackageInput!) {
      Package(input: $input) {
        id
        price
        templateGroups {
          templateGroupId
          printAd {
            templates
          }
        }
      }
    }
  `,
  GetSchedule: gql`
    query GetPublication($input: getPublicationInput!) {
      GetPublication(input: $input) {
        id
        portalId
        name
        description
        standardSchedule {
          MONDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          TUESDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          WEDNESDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          THURSDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          FRIDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          SATURDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          SUNDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
        }
        specialSchedule {
          MONDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          TUESDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          WEDNESDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          THURSDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          FRIDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          SATURDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
          SUNDAY {
            cutoffTime {
              hour
              minute
              dayTime
            }
            publishingOffsetInHours
          }
        }
      }
    }
  `,
  GetPublicationsForScheduling: gql`
    query GetPublicationsForScheduling($input: PublicationQueryInput!) {
      Publications(input: $input) {
        publications {
          id
          name
        }
      }
    }
  `,
  UpdateUserTemplate: gql`
    mutation UpdateUserTemplate($input: UserTemplatesInput) {
      UpdateUserTemplate(input: $input) {
        id
        orderId
        name
        imgUrl
        pdfUrl
      }
    }
  `,
  Payment: gql`
    mutation Payment($input: PaymentInput!) {
      Payment(input: $input) {
        paymentStatus
        transactionId
      }
    }
  `,
  IssueRefund: gql`
    mutation IssueRefund($input: IssueRefundInput!) {
      IssueRefund(input: $input) {
        paymentStatus
        transactionId
      }
    }
  `,
  ForgotPassword: gql`
    mutation ForgotPassword($input: ForgotPasswordInput!, $portalId: String, $host: String) {
      ForgotPassword(input: $input, portalId: $portalId, host: $host) {
        success
        error {
          message
          error
        }
      }
    }
  `,
  ChangePassword: gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
      ChangePassword(input: $input) {
        success
        error {
          message
          error
        }
      }
    }
  `,
  GetUserTemplate: gql`
    query GetUserTemplate($id: String!) {
      GetUserTemplate(id: $id) {
        id
        portalId
        orderId
        templateId
        name
        imgUrl
        pdfUrl
        title
        elements
        description
        title
        width
        height
        backgroundImage
        typeEditor
        code
        isActive
        type
        specialFields {
          id
          inputName
          specialTemplateFieldId
          fieldName
        }
      }
    }
  `,
  GetOrderPriceReview: gql`
    query GetOrder($input: PackageInput!) {
      GetOrder(input: $input) {
        id
        status
        userId
        friendlyId
        publications {
          publicationId
          portalId
        }
        templates {
          printTemplate {
            id
          }
        }
        scheduling {
          digitalAdd {
            templateGroupId
            schedulingDates {
              publicationId
              date
            }
          }
          printAdd {
            templateGroupId
            schedulingDates {
              publicationId
              date
            }
          }
        }
        pricing
        price {
          totalCost
          packageBasePrice
        }
        version
        publicationOrderData
        shippingAddress
      }
    }
  `,
  GetPrice: gql`
    query GetPrice($input: PriceInput!) {
      GetPrice(input: $input) {
        totalCost
        totalAdjustmentCosts
        totalTemplatePricing
        templatePricingByDay
        pricingAdjustmentCosts {
          pricingId
          value
          frequency
          name
        }
        packageBasePrice
      }
    }
  `,
  CheckJWTExpiration: gql`
    query CheckJWTExpiration {
      CheckJWTExpiration
    }
  `,
  FuneralHome: gql`
    query ($input: funeralHomeInput!) {
      GetFuneralHome(input: $input) {
        id
        name
        phone
        logo
      }
    }
  `,
  SaveDraftOrder: gql`
    mutation SaveDraftOrder($input: DraftOrderInput!) {
      SaveDraftOrder(input: $input)
    }
  `,
  LoadDraftOrder: gql`
    query LoadDraftOrder($orderId: String!, $portalId: String!, $userId: String!) {
      LoadDraftOrder(orderId: $orderId, portalId: $portalId, userId: $userId) {
        portalId
        id
        shippingAddress
        adTextHeights
        adData
        total
        adImagePreview
        packageId
        templateId
        currentPublication
        orderData
        publicationOrderData
        userId
        version
        orderNotes
      }
    }
  `,
  FetchOrderData: gql`
    query FetchOrderData($orderId: String!, $portalId: String!, $userId: String!) {
      FetchOrderData(orderId: $orderId, portalId: $portalId, userId: $userId) {
        id
        friendlyId
        adData
        publications
        scheduling
        orderNotes
        packageId
        portalId
        shippingAddress
        publicationOrderData
        status
        templates {
          printTemplateId
        }
        version
        status
      }
    }
  `,
  SearchPromoCode: gql`
    query ($input: promoCodeInput!) {
      SearchPromoCode(input: $input) {
        id
        code
        type
        details
        isActive
        value
      }
    }
  `,
  LoadOrderAppState: gql`
    query LoadOrderAppState($orderId: String!, $portalId: String!, $userId: String!) {
      LoadOrderAppState(orderId: $orderId, portalId: $portalId, userId: $userId) {
        adData
        adTextHeights
        currentPublication
        orderData
        scheduleDates
        selectedTemplate
        total
        adImagePreview
      }
    }
  `,
  GetOrderTransactions: gql`
    query Transactions($portalId: String!, $orderId: String!) {
      Transactions(portalId: $portalId, orderId: $orderId)
    }
  `,
  CancelOrder: gql`
    mutation CancelOrder($input: CancelOrderInput!) {
      CancelOrder(input: $input) {
        success
        message
      }
    }
  `,
  GetOrderInfo: gql`
    query GetOrder($input: PackageInput!) {
      GetOrder(input: $input) {
        id
        status
        userId
        friendlyId
        publicationOrderData
        shippingAddress
      }
    }
  `,
  NavItemsQuery: gql`
    query NavItemsQuery($portalId: String!) {
      NavItems(portalId: $portalId) {
        label
        url
      }
    }
  `,
  DeleteOrder: gql`
    mutation DeleteOrder($input: DeleteOrderInput!) {
      DeleteOrder(input: $input)
    }
  `,
};

export default request;
