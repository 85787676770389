const listConstants = {
  portal: "idPortal",
  template: "idTemplate",
  package: "idPackage",
  remember: "remember",
  publication: "publication",
  image: "image",
  type: "type",
  textArea: "textArea",
  date: "date",
  marginTop: "marginTop",
  marginRight: "marginRight",
  marginLeft: "marginLeft",
  marginBottom: "marginBottom",
  upload: "upload",
  hideHeader: "hideHeader",
  undefinedNan: "order#NaN",
  undefinedCost: "N/A",
  publicationId: "publicationId",
  packageId: "packageId",
  templateId: "templateId",
  filledTemplate: "filledTemplate",
  bodyTemplate: "bodyTemplate",
  idTemplateUser: "idTemplateUser",
  order: "order",
  typeTemplate: "typeTemplate",
  printAd: "printAd",
  facebookTemplate: "facebookTemplate",
  digitalTemplate: "digitalTemplate",
  templateGroupId: "templateGroupId",
  filledTemplateFacebook: "filledTemplateFacebook",
  filledTemplateDigital: "filledTemplateDigital",
  card: "card",
  elements: "elements",
  specialFields: "specialFields",
  inputName: "inputName",
  dataPortal: "dataPortal",
  templateUser: "templateUser",
  specialSchedule: "specialSchedule",
  standartSchedule: "standartSchedule",
  schedule: "schedule",
  pricePackage: "pricePackage",
  templateUserStorage: "templateUserStorage",
  orderRestore: "orderRestore",
  orderID: "orderID",
  buttonPay: "buttonLogin",
  buttonSignIn: "buttonSignIn",
  idPay: "payway-credit-card",
  textSuccess: "Successful Checkout",
  styleHeader: "headerLeftPosition",
  stylePrice: "payNow",
  printTemplate: "printTemplate",
  adData: "adData",
  inputsNameList: ["description", "input", "funeralName", "funeralPhone", "date"],
  //intended to be the actual order data once all refactoring is completed
  //tied into he orderContext
  orderMasterData: "orderMasterData",
  scheduling: "scheduling",
};

export default listConstants;
