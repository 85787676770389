import { get, isEmpty } from "lodash";

// this function will need to get pricing based
// on the portal the user is in
export default function handleFacebookAdPricing(masterOrder) {
  if (!isEmpty(masterOrder.facebookAd)) {
    const pricing = masterOrder?.selectedPackage?.pricing?.socialMediaPricing || {};

    const singlePricing = Number(pricing?.single || 0);
    const multiplePricing = Number(pricing?.multiple || 0);

    const durationOver2 = checkDurationOver2(masterOrder);

    // change this to get the price from the selected package
    return durationOver2 ? multiplePricing : singlePricing;
  } else {
    const selectedPackage = masterOrder?.selectedPackage || {};
    const { facebookAdSetting } = selectedPackage;

    if (facebookAdSetting === "REQUIRED") {
      const pricing = masterOrder?.selectedPackage?.pricing?.socialMediaPricing || {};

      const singlePricing = Number(pricing?.single || 0);
      const multiplePricing = Number(pricing?.multiple || 0);

      const durationOver2 = checkDurationOver2(masterOrder);

      // change this to get the price from the selected package
      return durationOver2 ? multiplePricing : singlePricing;
    }
  }
  return 0;

  function checkDurationOver2(masterOrder) {
    try {
      const publicationOrderData = get(masterOrder, "publicationOrderData");
      const keys = Object.keys(publicationOrderData || {});
      if (keys.length > 1) return true;
      let duration = 0;
      keys.some((key) => {
        duration += publicationOrderData?.[key]?.schedule?.length || 0;
        return duration > 2;
      });

      return duration >= 2;
    } catch (error) {
      return false;
    }
  }
}
