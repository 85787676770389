import PropTypes from "prop-types";
import styles from "../../../scss/components/general/ListItems.module.scss";

const ListItems = ({ selectedItem }) =>
  selectedItem?.items ? (
    <div className={styles.container}>
      {selectedItem.items.map((item, idx) => (
        <nav className={styles.listContainer} key={[item, idx].join("_")}>
          {item?.subtitle && <div className={styles.itemSubtitle}>{item.subtitle}</div>}
          {item?.list.map((el, index) => (
            <li className={styles.listItem} key={[el, index].join("_")}>
              {el}
            </li>
          ))}
        </nav>
      ))}
    </div>
  ) : null;

export default ListItems;

ListItems.defaultProps = {
  selectedItem: {},
};

ListItems.propTypes = {
  selectedItem: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        subtitle: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
};
