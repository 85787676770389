import { useContext, useCallback } from "react";

import { OrderDataContext, AdBuilderContext, PublicationContext, PricingContext } from "context/index";
import useMasterOrder from "redux/hooks/useMasterOrder";
import { removeFromLocalStorage } from "utils/storageWorks";

function useClearData() {
  const { clearOrderData } = useContext(OrderDataContext);
  const { reset } = useContext(AdBuilderContext);
  const { selectPublication } = useContext(PublicationContext);

  const { resetPricing } = useContext(PricingContext);
  const { clearMasterOrder } = useMasterOrder();

  // I have noticed that there is a delay from when
  // the function is called and when the data is cleared
  // so I added a delay to the function
  return useCallback((delay = 500) => {
    return new Promise((resolve) => {
      clearOrderData();
      reset();
      selectPublication(null);
      resetPricing();
      clearMasterOrder();
      removeFromLocalStorage("socialMediaAd");

      setTimeout(resolve, delay);
    });
  }, []);
}

export default useClearData;
