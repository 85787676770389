import { useContext } from "react";
import { AppContext } from "../context";

export const useSpinner = () => {
  const { setSpinner } = useContext(AppContext);

  return {
    setSpinner,
  };
};

export default useSpinner;
