const SCHEDULE_DAY_MAP = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export function getSchedule(publication) {
  const publicationSchedule = getPublicationSchedule(publication);
  const scheduledDays = [];
  for (let day in publicationSchedule) {
    scheduledDays[SCHEDULE_DAY_MAP[day]] = true;
  }

  return {
    scheduledDays,
    publicationSchedule,
  };
}

function getPublicationSchedule({ standartSchedule, specialSchedule }) {
  return standartSchedule || specialSchedule;
}
