import PropTypes from "prop-types";
import PrismLink from "../general/PrismLink";
import Dot from "../UI/Dot";

import styles from "../../../scss/components/header/HeaderLinkBox.module.scss";

const HeaderLinkBox = ({ asPath, name, typeStyle, typeMenu, href }) => (
  <div className={`${styles.menuBox} ${typeMenu && styles[typeMenu]}`}>
    <PrismLink href={href} asPath={asPath} typeStyle={typeStyle}>
      {name}
    </PrismLink>
    <div className={`${styles.dotBox}`}>
      <Dot />
    </div>
  </div>
);

HeaderLinkBox.defaultProps = {
  asPath: "",
  name: "",
  typeStyle: "",
  typeMenu: "",
  href: "",
};

HeaderLinkBox.propTypes = {
  asPath: PropTypes.string,
  name: PropTypes.string,
  typeStyle: PropTypes.string,
  typeMenu: PropTypes.string,
  href: PropTypes.string,
};

export default HeaderLinkBox;
