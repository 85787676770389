import { useState, useEffect, useContext } from "react";
import { getDataPortal } from "utils/storageWorks";

import { PortalContext } from "context/portalContext";

export default function useDataPortal() {
  const { portalId } = useContext(PortalContext);

  const cookiePortal = getDataPortal(portalId);

  const [dataPortal, setDataPortal] = useState(() => {
    return { portalId, id: portalId, portalID: portalId };
  });

  useEffect(() => {
    if (!cookiePortal.id) return;

    setDataPortal((prevState) => ({
      ...prevState,
      ...cookiePortal,
    }));
  }, []);

  return dataPortal;
}
