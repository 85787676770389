/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { createContext, useState, useEffect, useReducer, useCallback, useRef } from "react";
import { useQuery, makeVar, useReactiveVar } from "@apollo/client";

import useLogRocket from "hooks/useLogRocket";
import request from "../graphql/serverRequest";
import { getCookie } from "utils/cookiesWorker";
import { setToLocalStorage, removeFromLocalStorage } from "utils/storageWorks";

export const AppContext = createContext(undefined);

export const accessToken = makeVar("-1");
export const refreshToken = makeVar("-1");

export const AppProvider = (props) => {
  const [spinnerIsActive, setSpinner] = useState(false);
  const [title, setTitle] = useState("");

  const [overlayIsActive, setOverlay] = useState(false);
  const [overlayTitle, setOverlayTitle] = useState("");

  // This is for the sidebar menu
  const [selectedItems, _setSelectedItems] = useState([
    {
      stage: 0,
      title: "",
      item: [],
    },
  ]);

  const [ignored, forceUpdate] = useReducer((v) => v + 1, 0);

  const accessTokenVar = useReactiveVar(accessToken);

  const _authStatus = useRef(false);

  const setAuthStatus = useCallback(
    (value) => {
      _authStatus.current = value;
      forceUpdate();
    },
    [accessTokenVar]
  );

  useEffect(() => {
    const auth = getCookie("auth");

    if (auth) {
      const parsed = JSON.parse(auth);

      const { token, refreshToken: refresh } = parsed;

      accessToken(token);
      refreshToken(refresh);
    } else {
      accessToken(null);
      refreshToken(null);
    }
  }, []);

  const { profile, setProfile } = withProfile();

  if (process.env.NODE_ENV === "production") {
    useLogRocket(profile);
  }

  const setSelectedItems = useCallback((items) => {
    _setSelectedItems(items);
  }, []);

  const selectItem = useCallback(
    (data) => {
      if (data.stage === 0) {
        return _setSelectedItems([data]);
      }

      const newItems = [...selectedItems.filter((item) => item.stage !== data.stage), data].sort(
        (a, b) => a.stage - b.stage
      );

      return _setSelectedItems(newItems);
    },
    [selectedItems]
  );

  const clearOverlay = useCallback(() => {
    setOverlay(false);
    setOverlayTitle("");
  }, []);

  const providerValue = {
    spinnerIsActive,
    setSpinner,
    title,
    setTitle,
    profile,
    setProfile,
    authStatus: !!accessTokenVar,
    setAuthStatus,
    ignored,
    selectedItems,
    setSelectedItems,
    selectItem,
    overlayIsActive,
    overlayTitle,
    setOverlay,
    setOverlayTitle,
    clearOverlay,
  };

  return <AppContext.Provider value={providerValue}>{props.children}</AppContext.Provider>;
};

function withProfile() {
  const [profile, _setProfile] = useState(null);
  const token = useReactiveVar(accessToken);

  const setProfile = useCallback((data) => {
    _setProfile(data);
    if (data === null) {
      removeFromLocalStorage("_profile");
    } else {
      setToLocalStorage("_profile", data);
    }
  }, []);

  const { data, refetch } = useQuery(request.getMyProfile, {
    onCompleted: ({ getMyProfile }) => {
      setProfile(getMyProfile);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (token && !data) {
      refetch();
    }
  }, [token, data]);

  return { profile, setProfile };
}
