import { useEffect } from "react";
import LogRocket from "logrocket";

export default function useLogRocket(profile) {
  useEffect(() => {
    LogRocket.init("xs4o0s/prism-wx2r7");
  }, []);

  useEffect(() => {
    if (!profile) return;

    LogRocket.identify(profile?.id, {
      name: `${profile?.firstName} ${profile?.lastName}`,
      email: profile?.email,
      userType: profile?.userType,
    });
  }, [profile]);
}
