import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { isEmpty, get } from "lodash";
import requests from "../graphql/serverRequest";
import { getSchedule } from "utils/getSchedule";
import useMasterOrder from "../redux/hooks/useMasterOrder";
import { useSpinner } from "hooks/useSpinner";
import { useDateSelector } from "hooks/useDateSelector";
import { isPublishingDayAllowed } from "utils/isPublishingDayAllowed";

export function usePublicationDatePicker({ publication }) {
  const { setSpinner } = useSpinner();
  // const [selectedDays, updateSelectedDays] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [scheduledDays, setScheduleDays] = useState([]);
  const { masterOrder } = useMasterOrder();
  const { selectedDays, updateSelectedDays, setSelectedDays } = useDateSelector(
    get(masterOrder, `publicationOrderData.${publication.id}.schedule`, [])
  );
  const [schedule, setSchedule] = useState({});
  const [publicationDeadlines, setPublicationDeadlines] = useState([]);
  useEffect(() => {
    if (!isEmpty(masterOrder)) {
      updateSelectedDays(get(masterOrder, `publicationOrderData.${publication.id}.schedule`, []));
    }
  }, [isEmpty(masterOrder), publication?.id]);

  const { loading } = useQuery(requests.GetSchedule, {
    variables: {
      input: {
        id: publication.id,
        portalId: masterOrder?.portal?.id,
      },
    },
    onCompleted(res) {
      const { scheduledDays, publicationSchedule } = getSchedule(res.GetPublication);
      setPublicationDeadlines(
        res.GetPublication.standardSchedule ? res.GetPublication.standardSchedule : res.GetPublication.specialSchedule
      );
      setScheduleDays(scheduledDays);
      setSchedule(publicationSchedule);
    },
  });

  useEffect(() => {
    setSpinner(loading);
  }, [loading]);

  useEffect(() => {
    if (canSetScheudle()) {
      let nextSchedule = get(masterOrder, `publicationOrderData.${publication.id}.schedule`, []);
      if (!masterOrder.editingExistingOrder) {
        nextSchedule = removeDatesPreviousDates(nextSchedule);
      }
      updateSelectedDays(nextSchedule);
      if (!isEmpty(get(masterOrder, `publicationOrderData.${publication.id}.schedule`))) {
        setShowDatePicker(false);
      }
    }
  }, [canSetScheudle()]);

  function canSetScheudle() {
    return !isEmpty(publicationDeadlines) && !isEmpty(masterOrder);
  }

  return {
    showDatePicker,
    setShowDatePicker,
    scheduledDays,
    setScheduleDays,
    schedule,
    setSchedule,
    selectedDays,
    updateSelectedDays,
    setSelectedDays,
    validatePublishingDate,
    publicationDeadlines,
  };

  function removeDatesPreviousDates() {
    return selectedDays.filter((date) => {
      return validatePublishingDate(publicationDeadlines, getDateFromDateObj(new Date(date)));
    });
  }

  function validatePublishingDate(scheduleDays, dateInQuestion) {
    const publicationDays = getScheduleDaysFromPublicationDays(scheduleDays);
    return isPublishingDayAllowed(publicationDays, dateInQuestion);
  }
}

//converts date into object that
//the checker function is expecting
export function getDateFromDateObj(date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  };
}

// mutates the scheule days into an array that than be iterated.
export function getScheduleDaysFromPublicationDays(publicationDays) {
  let scheduleDays = [];
  for (var day in publicationDays) {
    const newDay = { ...publicationDays[day] };
    if (newDay) {
      newDay.publishDay = day;
      scheduleDays.push(newDay);
    }
  }
  return scheduleDays;
}
