import { userId } from "hooks/useProfile";
import info from "../../package.json";

function noop() {}

export default new Proxy(
  {},
  {
    get(_, prop) {
      return async (...args) => {
        try {
          const level = prop.toUpperCase();
          const message = args?.[0];
          const data = args?.[1] || {};

          console.log(`[${level}] [${data?.orderId}] ${message}`);

          return fetch(process.env.NEXT_PUBLIC_LOGGING_API, {
            method: "POST",
            body: JSON.stringify({
              level,
              message,
              dt: new Date().toISOString(),
              ...data,
              userId,
              version: info.version,
              auth: process.env.NEXT_PUBLIC_LOGTAIL_BROWSER_TOKEN,
            }),
          }).catch(noop);
        } catch (e) {
          console.error(e);
        }
      };
    },
  }
);
