import PropTypes from "prop-types";

import styles from "../../../scss/components/general/Button.module.scss";

const Button = ({
  children,
  onClick,
  color,
  typeBtn,
  disabled,
  name,
  id,
  active,
  analytics,
  action,
  category,
  label,
  value,
}) => (
  <button
    className={`${styles.btn} ${color ? styles[color] : ""} ${active ? styles[active] : ""} ${
      disabled ? styles.disabled : ""
    }`}
    onClick={(e) => onClick(e, id)}
    type={typeBtn ? "button" : "submit"}
    disabled={disabled}
    name={name}
    id={id}
    {...(analytics && { "data-analytics": analytics })}
    {...(category && { "data-category": category })}
    {...(action && { "data-action": action })}
    {...(label && { "data-label": label })}
    {...(value && { "data-value": value })}
  >
    {children}
  </button>
);

Button.defaultProps = {
  onClick: () => {},
  color: "",
  typeBtn: true,
  disabled: false,
  name: "",
  id: "",
  active: "",
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  typeBtn: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.string,
};

export default Button;
