import { useRouter } from "next/router";

const Routes = () => {
  const router = useRouter();

  const checkPage = (data) => {
    const check = data.indexOf(router.asPath);
    return check !== -1;
  };

  const checkMainHeader = (data) => {
    let protect = false;
    data.map((item) => {
      if (item.path === router.asPath || item.path === router.pathname) {
        protect = item.headerMain;
      }
      return false;
    });
    return protect;
  };

  const checkLeftHeader = (data) => {
    let leftHeader = false;
    data.map((item) => {
      if (item.path === router.asPath) {
        leftHeader = item.headerLeft;
      }
      return false;
    });
    return leftHeader;
  };

  const checkDashboard = (data) => {
    let protect = false;
    data.map((item) => {
      if (item.path === router.asPath || item.path === router.pathname) {
        protect = item.headerDashboard;
      }
      return false;
    });
    return protect;
  };

  const checkProtect = (data) => {
    let protect = false;
    data.map((item) => {
      if (item.path === router.asPath || item.path === router.pathname) {
        protect = item.protect;
      }
      return false;
    });
    return protect;
  };

  const checkAuthRequired = (data) => {
    let authRequired = false;
    data.map((item) => {
      if (item.path === router.asPath || item.path === router.pathname) {
        authRequired = item.authRequired;
      }
      return false;
    });
    return authRequired;
  };

  return {
    checkPage,
    checkProtect,
    checkMainHeader,
    checkLeftHeader,
    checkDashboard,
    checkAuthRequired,
  };
};

export default Routes;
