import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useEffect, useState, useContext } from "react";
import HeaderHomePage from "../../components/header/HeaderHomePage";
import HeaderLeft from "../../components/header/HeaderLeft";
import constants from "../../constants/storageWorks";
import { removeLocalStorage } from "../../utils/storageWorks";

import { AppContext } from "context/appContext";

import usePortalNavItems from "hooks/usePortalNavItems";

const Header = ({ checkHeader, authStatus }) => {
  const router = useRouter();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [url, setUrl] = useState("");
  const [fixedSelectedItems, setFixedSelectedItems] = useState([]);

  const { selectedItems } = useContext(AppContext);

  const navItems = usePortalNavItems();

  useEffect(() => {
    setFixedSelectedItems(
      selectedItems.map((v) => {
        if (v.items) {
          return {
            ...v,
            items: v.items.map((_v) => ({
              ..._v,
              subtitle: "",
            })),
          };
        }

        return v;
      })
    );
  }, [selectedItems]);

  const createAnAd = () => {
    removeLocalStorage(constants.publication);
    removeLocalStorage(constants.order);
    removeLocalStorage(constants.orderID);
    router.push("/create-publication");
  };

  const sidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    if (url !== router.asPath) {
      setUrl(`${router.asPath}`);
      setOpenSidebar(false);
    }
  }, [router.asPath]);

  return (
    <>
      <HeaderHomePage
        authStatus={authStatus}
        sidebarOpen={sidebarOpen}
        createAnAd={createAnAd}
        openSidebar={openSidebar}
        styleMobile={!checkHeader ? constants.hideHeader : ""}
        listMenu={navItems}
      />
      {!checkHeader && <HeaderLeft asPath={router.asPath} selectedItem={fixedSelectedItems} />}
    </>
  );
};

Header.defaultProps = {
  checkHeader: false,
  authStatus: false,
  selectedItem: [],
};

Header.propTypes = {
  checkHeader: PropTypes.bool,
  authStatus: PropTypes.bool,
  selectedItem: PropTypes.array,
};

export default Header;
