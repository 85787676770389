import React, { createContext, useState, useEffect } from "react";

export const PortalContext = createContext(undefined);

export const PortalProvider = ({ portalId, children }) => {
  const [portal, setPortal] = useState(undefined);

  useEffect(() => {
    if (portalId) {
      setPortal({ id: portalId });
    }
  }, [portalId]);

  const providerValue = {
    portalId: portal?.id,
  };

  return <PortalContext.Provider value={providerValue}>{children}</PortalContext.Provider>;
};

export default PortalProvider;
