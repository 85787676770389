import { useContext } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import { format } from "date-fns";
import Logo from "../general/Logo";
import PrismLink from "../general/PrismLink";
import styles from "../../../scss/components/header/HeaderLeft.module.scss";
import TextList from "../general/TextList";
import { OrderDataContext } from "context/index";
import useMasterOrder from "../../redux/hooks/useMasterOrder";
import useDataPortal from "hooks/useDataPortal";

const HeaderLeft = ({ t, asPath }) => {
  const { orderData, scheduleDates } = useContext(OrderDataContext);
  const { masterOrder } = useMasterOrder();
  const portal = useDataPortal();

  const selectedPackage = orderData?.selectedPackage;
  const selectedTemplate = orderData?.selectedTemplate;

  return (
    <>
      <div className={styles.container}>
        <Logo href="/" urlLogo={`/static/images/portals/${portal?.id}.png`} styleType="logoLeftHeader" />

        {asPath === "/create-account" && (
          <div className={styles.loginBox}>
            <span className={styles.label}>{t("alreadyHaveAnAccount")}</span>
            <PrismLink href="/login" typeStyle="createAccount">
              <span>{t("logInToYourAccount")}</span>
            </PrismLink>
          </div>
        )}
        <div className={styles.promoBox}>
          {false && <h1>Limited time offer!</h1>}
          <p>Purchase your notice for two dates and receive a third date at no charge.</p>
        </div>

        {asPath.split("/")[1] === "create-publication" && (
          <div className={styles.boxInfo}>
            <TextList
              title={"YOUR SELECTED"}
              selectedItem={{
                title: masterOrder?.selectedPublications?.length > 1 ? "Publications" : "Publication",
                item: masterOrder?.selectedPublications?.map(getOrderNames),
              }}
            />

            {masterOrder?.selectedPackage && (
              <TextList
                title={"YOUR SELECTED"}
                selectedItem={{
                  title: "Package",
                  item: [selectedPackage?.name],
                }}
              />
            )}

            {masterOrder?.selectedTemplate && (
              <TextList
                title={"YOUR SELECTED"}
                selectedItem={{
                  title: "Template",
                  item: [selectedTemplate?.name],
                }}
              />
            )}

            {!!scheduleDates.length && (
              <TextList
                title={"YOUR SELECTED"}
                selectedItem={{
                  title: "Dates",
                  item: scheduleDates.map(({ date }) => `${format(new Date(date), "MMMM, EEEE dd")}`),
                }}
              />
            )}
          </div>
        )}

        <div className={styles.logoFooter}>
          <div className={styles.policeBox}>
            <PrismLink href="/privacy" typeStyle="policyLink">
              {t("privacy")}
            </PrismLink>
            <PrismLink href="/privacy" typeStyle="policyLink">
              {t("terms")}
            </PrismLink>
          </div>
        </div>
      </div>
    </>
  );
};

function getOrderNames(publication) {
  return publication?.name;
}

HeaderLeft.defaultProps = {
  asPath: "",
  selectedItem: [],
};

HeaderLeft.propTypes = {
  t: PropTypes.func.isRequired,
  asPath: PropTypes.string,
  selectedItem: PropTypes.array,
};

export default withTranslation("indexPage")(HeaderLeft);
