const defaultCalendarState = {
  arraySelectedDates: [],
  publicationName: "",
  id: "", // publication.id,
  //
  // these attributes were moved to standalone variables
  // leaving here for now to make sure isn't a bug if I remove them
  // selectedDates: {},
  // infoMonth: {
  //   month: 0,
  //   year: 0,
  // },
  // amountOfDays: 0,
  //
  // We don't use this anymore
  // allPublications: true,
};

export default defaultCalendarState;
