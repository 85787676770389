import PropTypes from "prop-types";
import Button from "../UI/Button";
import Dot from "../UI/Dot";
import HeaderMenu from "./HeaderMenu";
import styles from "../../../scss/components/header/HeaderSideBar.module.scss";

const HeaderSideBar = ({ sidebarOpen, createAnAd, textBtn, authStatus, listMenu }) => (
  <div className={styles.container}>
    <div className={styles.close}>
      <Button color="btnSidebar" onClick={sidebarOpen}>
        <Dot />
        <Dot />
        <Dot />
      </Button>
    </div>
    <div className={styles.menu}>
      {/* <Button onClick={createAnAd} color="sidebarBtn">
        {textBtn}
      </Button> */}
      <HeaderMenu authStatus={authStatus} listMenu={listMenu} />
    </div>
  </div>
);

HeaderSideBar.defaultProps = {
  sidebarOpen: () => {},
  createAnAd: () => {},
  textBtn: "",
  authStatus: false,
  listMenu: [],
};
HeaderSideBar.propTypes = {
  sidebarOpen: PropTypes.func,
  createAnAd: PropTypes.func,
  textBtn: PropTypes.string,
  authStatus: PropTypes.bool,
  listMenu: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HeaderSideBar;
